
if (import.meta.hot) {
  import.meta.hot.accept((mod) => {
    const router = import.meta.hot.data.router
    const generateRoutes = import.meta.hot.data.generateRoutes
    if (!router || !generateRoutes) {
      import.meta.hot.invalidate('[nuxt] Cannot replace routes because there is no active router. Reloading.')
      return
    }
    router.clearRoutes()
    const routes = generateRoutes(mod.default || mod)
    function addRoutes (routes) {
      for (const route of routes) {
        router.addRoute(route)
      }
      router.replace(router.currentRoute.value.fullPath)
    }
    if (routes && 'then' in routes) {
      routes.then(addRoutes)
    } else {
      addRoutes(routes)
    }
  })
}

export function handleHotUpdate(_router, _generateRoutes) {
  if (import.meta.hot) {
    import.meta.hot.data ||= {}
    import.meta.hot.data.router = _router
    import.meta.hot.data.generateRoutes = _generateRoutes
  }
}
import { default as infodqBSM9ll39V6_MEmjQLSUBnx4GjjpcCpmCGA7vmxu_oMeta } from "/builds/stimuleringsfonds-creatieve-industrie/stimuleringsfonds-frontend/pages/info.vue?macro=true";
import { default as indexicOdrhM4ZElOh0IR_3cQmhy1TGfaMHpi0udGu1pe7O8Meta } from "/builds/stimuleringsfonds-creatieve-industrie/stimuleringsfonds-frontend/pages/index.vue?macro=true";
import { default as searchl5M_45fAwpAon_45EuLgzWS0y3h_45ZVHtLSdby6o8OwStIaoMeta } from "/builds/stimuleringsfonds-creatieve-industrie/stimuleringsfonds-frontend/pages/search.vue?macro=true";
import { default as discover587ESiuxxEbPGXPCTfHWQtt9f4Z1F7VUHEaeNGUipJYMeta } from "/builds/stimuleringsfonds-creatieve-industrie/stimuleringsfonds-frontend/pages/discover.vue?macro=true";
import { default as _91_46_46_46slug_93aEZu4tAID2lNlqn0Ejj_4510CkJzlWoqL5lm_45Z_45EvB82QMeta } from "/builds/stimuleringsfonds-creatieve-industrie/stimuleringsfonds-frontend/pages/[...slug].vue?macro=true";
import { default as index96D_1eNxOD96dGnFAOQk2mMRmATm7fNYUFARFgIi4tsMeta } from "/builds/stimuleringsfonds-creatieve-industrie/stimuleringsfonds-frontend/pages/news/index.vue?macro=true";
import { default as _91slug_93mfVrmwttoz21brQ1EWM25ekY1NMPKVYb_45AbSwtIicMMMeta } from "/builds/stimuleringsfonds-creatieve-industrie/stimuleringsfonds-frontend/pages/news/[slug].vue?macro=true";
import { default as indexxdjEhHL_P5nA7Z9aPnCEIGV3jzQJAQwX5VUnbsTeelcMeta } from "/builds/stimuleringsfonds-creatieve-industrie/stimuleringsfonds-frontend/pages/events/index.vue?macro=true";
import { default as index1MmRhEB5YknBMNRkDWW_U_456S2bC_IH3KTUCaWz35RzoMeta } from "/builds/stimuleringsfonds-creatieve-industrie/stimuleringsfonds-frontend/pages/grants/index.vue?macro=true";
import { default as _91slug_93QUPpB_z_04qj8ZY_PwWM79Pd1G3ZQiZwa78Om1qyd2cMeta } from "/builds/stimuleringsfonds-creatieve-industrie/stimuleringsfonds-frontend/pages/events/[slug].vue?macro=true";
import { default as _91slug_93y4kgF5UYRYbZlGs2VQg_JfF2JXM3LRPQsXCJq0Yr4LsMeta } from "/builds/stimuleringsfonds-creatieve-industrie/stimuleringsfonds-frontend/pages/grants/[slug].vue?macro=true";
import { default as indexlPIM6oEfbTM82goXZbEejCbcHF2bYQkdqNwajpFWxhcMeta } from "/builds/stimuleringsfonds-creatieve-industrie/stimuleringsfonds-frontend/pages/articles/index.vue?macro=true";
import { default as indexLKSvjZ2pN5Xc0ZSYiwntRNacaYwiJ8pJ5WRE72Iv1OQMeta } from "/builds/stimuleringsfonds-creatieve-industrie/stimuleringsfonds-frontend/pages/dossiers/index.vue?macro=true";
import { default as indexbRJvXoGg8O9WZMHHR0p9TbJlvijfDkUVCPEWuKVrsVkMeta } from "/builds/stimuleringsfonds-creatieve-industrie/stimuleringsfonds-frontend/pages/projects/index.vue?macro=true";
import { default as _91slug_931ePZ1h8qt5gK0kXhCfunTvbx_455L6vAhzok9V_45WS_jo8Meta } from "/builds/stimuleringsfonds-creatieve-industrie/stimuleringsfonds-frontend/pages/articles/[slug].vue?macro=true";
import { default as _91slug_93GzGQ_45Tn53p4wcjiFKGSKpKnZ9dFHCPX5hDtDuZzvSooMeta } from "/builds/stimuleringsfonds-creatieve-industrie/stimuleringsfonds-frontend/pages/dossiers/[slug].vue?macro=true";
import { default as _91slug_93vyOCyVNk6NRRkdnT4wu8nBl_j4mteLKzPDEjgBDA4PIMeta } from "/builds/stimuleringsfonds-creatieve-industrie/stimuleringsfonds-frontend/pages/projects/[slug].vue?macro=true";
import { default as index_02AZv5AQwacQn6p6Xn0EF6aFIkX5wWGCuLUku_FndgMeta } from "/builds/stimuleringsfonds-creatieve-industrie/stimuleringsfonds-frontend/pages/grants-issued/index.vue?macro=true";
import { default as _91slug_93AUYWYnfiBOAqHCXMdbXKmBzbsQiML2QpIAqX31hDJXYMeta } from "/builds/stimuleringsfonds-creatieve-industrie/stimuleringsfonds-frontend/pages/grants-issued/[slug].vue?macro=true";
import { default as component_45stub52wm2MonuQE1yI1cyhQhC_45dpIIgSY1AE0jFdrJQ_45Hb8Meta } from "/builds/stimuleringsfonds-creatieve-industrie/stimuleringsfonds-frontend/node_modules/.pnpm/nuxt@3.16.1_@parcel+watcher@2.5.1_@types+node@22.13.13_db0@0.3.1_eslint@8.57.1_ioredis@_e9ae249bc6db273cd69bd4f8870fc4ce/node_modules/nuxt/dist/pages/runtime/component-stub.js?macro=true";
import { default as component_45stub52wm2MonuQE1yI1cyhQhC_45dpIIgSY1AE0jFdrJQ_45Hb8 } from "/builds/stimuleringsfonds-creatieve-industrie/stimuleringsfonds-frontend/node_modules/.pnpm/nuxt@3.16.1_@parcel+watcher@2.5.1_@types+node@22.13.13_db0@0.3.1_eslint@8.57.1_ioredis@_e9ae249bc6db273cd69bd4f8870fc4ce/node_modules/nuxt/dist/pages/runtime/component-stub.js";
export default [
  {
    name: "info___nl",
    path: "/info",
    component: () => import("/builds/stimuleringsfonds-creatieve-industrie/stimuleringsfonds-frontend/pages/info.vue")
  },
  {
    name: "info___en",
    path: "/en/info",
    component: () => import("/builds/stimuleringsfonds-creatieve-industrie/stimuleringsfonds-frontend/pages/info.vue")
  },
  {
    name: "index___nl",
    path: "/",
    component: () => import("/builds/stimuleringsfonds-creatieve-industrie/stimuleringsfonds-frontend/pages/index.vue")
  },
  {
    name: "index___en",
    path: "/en",
    component: () => import("/builds/stimuleringsfonds-creatieve-industrie/stimuleringsfonds-frontend/pages/index.vue")
  },
  {
    name: "search___nl",
    path: "/zoek",
    component: () => import("/builds/stimuleringsfonds-creatieve-industrie/stimuleringsfonds-frontend/pages/search.vue")
  },
  {
    name: "search___en",
    path: "/en/search",
    component: () => import("/builds/stimuleringsfonds-creatieve-industrie/stimuleringsfonds-frontend/pages/search.vue")
  },
  {
    name: "discover___nl",
    path: "/ontdek",
    component: () => import("/builds/stimuleringsfonds-creatieve-industrie/stimuleringsfonds-frontend/pages/discover.vue")
  },
  {
    name: "discover___en",
    path: "/en/discover",
    component: () => import("/builds/stimuleringsfonds-creatieve-industrie/stimuleringsfonds-frontend/pages/discover.vue")
  },
  {
    name: "slug___nl",
    path: "/:slug(.*)*",
    component: () => import("/builds/stimuleringsfonds-creatieve-industrie/stimuleringsfonds-frontend/pages/[...slug].vue")
  },
  {
    name: "slug___en",
    path: "/en/:slug(.*)*",
    component: () => import("/builds/stimuleringsfonds-creatieve-industrie/stimuleringsfonds-frontend/pages/[...slug].vue")
  },
  {
    name: "news___nl",
    path: "/nieuws",
    component: () => import("/builds/stimuleringsfonds-creatieve-industrie/stimuleringsfonds-frontend/pages/news/index.vue")
  },
  {
    name: "news___en",
    path: "/en/news",
    component: () => import("/builds/stimuleringsfonds-creatieve-industrie/stimuleringsfonds-frontend/pages/news/index.vue")
  },
  {
    name: "news-slug___nl",
    path: "/nieuws/:slug()",
    component: () => import("/builds/stimuleringsfonds-creatieve-industrie/stimuleringsfonds-frontend/pages/news/[slug].vue")
  },
  {
    name: "news-slug___en",
    path: "/en/news/:slug()",
    component: () => import("/builds/stimuleringsfonds-creatieve-industrie/stimuleringsfonds-frontend/pages/news/[slug].vue")
  },
  {
    name: "events___nl",
    path: "/agenda",
    component: () => import("/builds/stimuleringsfonds-creatieve-industrie/stimuleringsfonds-frontend/pages/events/index.vue")
  },
  {
    name: "events___en",
    path: "/en/agenda",
    component: () => import("/builds/stimuleringsfonds-creatieve-industrie/stimuleringsfonds-frontend/pages/events/index.vue")
  },
  {
    name: "grants___nl",
    path: "/subsidies",
    component: () => import("/builds/stimuleringsfonds-creatieve-industrie/stimuleringsfonds-frontend/pages/grants/index.vue")
  },
  {
    name: "grants___en",
    path: "/en/grants",
    component: () => import("/builds/stimuleringsfonds-creatieve-industrie/stimuleringsfonds-frontend/pages/grants/index.vue")
  },
  {
    name: "events-slug___nl",
    path: "/agenda/:slug()",
    component: () => import("/builds/stimuleringsfonds-creatieve-industrie/stimuleringsfonds-frontend/pages/events/[slug].vue")
  },
  {
    name: "events-slug___en",
    path: "/en/agenda/:slug()",
    component: () => import("/builds/stimuleringsfonds-creatieve-industrie/stimuleringsfonds-frontend/pages/events/[slug].vue")
  },
  {
    name: "grants-slug___nl",
    path: "/subsidies/:slug()",
    component: () => import("/builds/stimuleringsfonds-creatieve-industrie/stimuleringsfonds-frontend/pages/grants/[slug].vue")
  },
  {
    name: "grants-slug___en",
    path: "/en/grants/:slug()",
    component: () => import("/builds/stimuleringsfonds-creatieve-industrie/stimuleringsfonds-frontend/pages/grants/[slug].vue")
  },
  {
    name: "articles___nl",
    path: "/publicaties",
    component: () => import("/builds/stimuleringsfonds-creatieve-industrie/stimuleringsfonds-frontend/pages/articles/index.vue")
  },
  {
    name: "articles___en",
    path: "/en/publications",
    component: () => import("/builds/stimuleringsfonds-creatieve-industrie/stimuleringsfonds-frontend/pages/articles/index.vue")
  },
  {
    name: "dossiers___nl",
    path: "/dossiers",
    component: () => import("/builds/stimuleringsfonds-creatieve-industrie/stimuleringsfonds-frontend/pages/dossiers/index.vue")
  },
  {
    name: "dossiers___en",
    path: "/en/dossiers",
    component: () => import("/builds/stimuleringsfonds-creatieve-industrie/stimuleringsfonds-frontend/pages/dossiers/index.vue")
  },
  {
    name: "projects___nl",
    path: "/projecten",
    component: () => import("/builds/stimuleringsfonds-creatieve-industrie/stimuleringsfonds-frontend/pages/projects/index.vue")
  },
  {
    name: "projects___en",
    path: "/en/projects",
    component: () => import("/builds/stimuleringsfonds-creatieve-industrie/stimuleringsfonds-frontend/pages/projects/index.vue")
  },
  {
    name: "articles-slug___nl",
    path: "/publicaties/:slug()",
    component: () => import("/builds/stimuleringsfonds-creatieve-industrie/stimuleringsfonds-frontend/pages/articles/[slug].vue")
  },
  {
    name: "articles-slug___en",
    path: "/en/publications/:slug()",
    component: () => import("/builds/stimuleringsfonds-creatieve-industrie/stimuleringsfonds-frontend/pages/articles/[slug].vue")
  },
  {
    name: "dossiers-slug___nl",
    path: "/dossiers/:slug()",
    component: () => import("/builds/stimuleringsfonds-creatieve-industrie/stimuleringsfonds-frontend/pages/dossiers/[slug].vue")
  },
  {
    name: "dossiers-slug___en",
    path: "/en/dossiers/:slug()",
    component: () => import("/builds/stimuleringsfonds-creatieve-industrie/stimuleringsfonds-frontend/pages/dossiers/[slug].vue")
  },
  {
    name: "projects-slug___nl",
    path: "/projecten/:slug()",
    component: () => import("/builds/stimuleringsfonds-creatieve-industrie/stimuleringsfonds-frontend/pages/projects/[slug].vue")
  },
  {
    name: "projects-slug___en",
    path: "/en/projects/:slug()",
    component: () => import("/builds/stimuleringsfonds-creatieve-industrie/stimuleringsfonds-frontend/pages/projects/[slug].vue")
  },
  {
    name: "grants-issued___nl",
    path: "/toekenningen",
    component: () => import("/builds/stimuleringsfonds-creatieve-industrie/stimuleringsfonds-frontend/pages/grants-issued/index.vue")
  },
  {
    name: "grants-issued___en",
    path: "/en/grants-issued",
    component: () => import("/builds/stimuleringsfonds-creatieve-industrie/stimuleringsfonds-frontend/pages/grants-issued/index.vue")
  },
  {
    name: "grants-issued-slug___nl",
    path: "/toekenningen/:slug()",
    component: () => import("/builds/stimuleringsfonds-creatieve-industrie/stimuleringsfonds-frontend/pages/grants-issued/[slug].vue")
  },
  {
    name: "grants-issued-slug___en",
    path: "/en/grants-issued/:slug()",
    component: () => import("/builds/stimuleringsfonds-creatieve-industrie/stimuleringsfonds-frontend/pages/grants-issued/[slug].vue")
  },
  {
    name: component_45stub52wm2MonuQE1yI1cyhQhC_45dpIIgSY1AE0jFdrJQ_45Hb8Meta?.name,
    path: "/sitemap.xml",
    component: component_45stub52wm2MonuQE1yI1cyhQhC_45dpIIgSY1AE0jFdrJQ_45Hb8
  },
  {
    name: component_45stub52wm2MonuQE1yI1cyhQhC_45dpIIgSY1AE0jFdrJQ_45Hb8Meta?.name,
    path: "/en/sitemap.xml",
    component: component_45stub52wm2MonuQE1yI1cyhQhC_45dpIIgSY1AE0jFdrJQ_45Hb8
  }
]