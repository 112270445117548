
// @ts-nocheck


export const localeCodes =  [
  "nl",
  "en"
]

export const localeLoaders = {
  nl: [
    {
      key: "locale_nl_45NL_46json_77d25224",
      load: () => import("#nuxt-i18n/77d25224" /* webpackChunkName: "locale_nl_45NL_46json_77d25224" */),
      cache: true
    }
  ],
  en: [
    {
      key: "locale_en_45US_46json_b5c4e6ae",
      load: () => import("#nuxt-i18n/b5c4e6ae" /* webpackChunkName: "locale_en_45US_46json_b5c4e6ae" */),
      cache: true
    }
  ]
}

export const vueI18nConfigs = [
  () => import("#nuxt-i18n/aa48bab5" /* webpackChunkName: "config_i18n_46config_46ts_aa48bab5" */)
]

export const nuxtI18nOptions = {
  restructureDir: "i18n",
  experimental: {
    localeDetector: "",
    switchLocalePathLinkSSR: false,
    autoImportTranslationFunctions: false,
    typedPages: true,
    typedOptionsAndMessages: false,
    generatedLocaleFilePathFormat: "absolute",
    alternateLinkCanonicalQueries: false,
    hmr: true
  },
  bundle: {
    compositionOnly: true,
    runtimeOnly: false,
    fullInstall: true,
    dropMessageCompiler: false,
    optimizeTranslationDirective: true
  },
  compilation: {
    strictMessage: true,
    escapeHtml: false,
    jit: false
  },
  customBlocks: {
    defaultSFCLang: "json",
    globalSFCScope: false
  },
  locales: [
    {
      code: "nl",
      language: "nl-NL",
      craftSiteHandle: "default",
      files: [
        "/builds/stimuleringsfonds-creatieve-industrie/stimuleringsfonds-frontend/i18n/locales/nl-NL.json"
      ]
    },
    {
      code: "en",
      language: "en-US",
      craftSiteHandle: "english",
      files: [
        "/builds/stimuleringsfonds-creatieve-industrie/stimuleringsfonds-frontend/i18n/locales/en-US.json"
      ]
    }
  ],
  defaultLocale: "nl",
  defaultDirection: "ltr",
  routesNameSeparator: "___",
  trailingSlash: false,
  defaultLocaleRouteNameSuffix: "default",
  strategy: "prefix_except_default",
  lazy: true,
  langDir: "locales",
  rootRedirect: undefined,
  detectBrowserLanguage: false,
  differentDomains: false,
  baseUrl: "https://www.stimuleringsfonds.nl",
  customRoutes: "page",
  pages: {},
  skipSettingLocaleOnNavigate: false,
  types: "composition",
  debug: false,
  parallelPlugin: false,
  multiDomainLocales: false,
  i18nModules: []
}

export const normalizedLocales = [
  {
    code: "nl",
    language: "nl-NL",
    craftSiteHandle: "default",
    files: [
      {
        path: "/builds/stimuleringsfonds-creatieve-industrie/stimuleringsfonds-frontend/i18n/locales/nl-NL.json",
        cache: undefined
      }
    ]
  },
  {
    code: "en",
    language: "en-US",
    craftSiteHandle: "english",
    files: [
      {
        path: "/builds/stimuleringsfonds-creatieve-industrie/stimuleringsfonds-frontend/i18n/locales/en-US.json",
        cache: undefined
      }
    ]
  }
]

export const NUXT_I18N_MODULE_ID = "@nuxtjs/i18n"
export const parallelPlugin = false
export const isSSG = false
export const hasPages = true

export const DEFAULT_COOKIE_KEY = "i18n_redirected"
export const DEFAULT_DYNAMIC_PARAMS_KEY = "nuxtI18nInternal"
export const SWITCH_LOCALE_PATH_LINK_IDENTIFIER = "nuxt-i18n-slp"
/** client **/

/** client-end **/