import routerOptions0 from "/builds/stimuleringsfonds-creatieve-industrie/stimuleringsfonds-frontend/node_modules/.pnpm/nuxt@3.16.1_@parcel+watcher@2.5.1_@types+node@22.13.13_db0@0.3.1_eslint@8.57.1_ioredis@_e9ae249bc6db273cd69bd4f8870fc4ce/node_modules/nuxt/dist/pages/runtime/router.options.js";
import routerOptions1 from "/builds/stimuleringsfonds-creatieve-industrie/stimuleringsfonds-frontend/app/router.options.ts";
const configRouterOptions = {
  hashMode: false,
  scrollBehaviorType: "auto"
}
export const hashMode = routerOptions0.hashMode ?? false
export default {
...configRouterOptions,
...routerOptions0,
...routerOptions1,
}