import payload_plugin_w0HDFr_j2IsnaU6rXjh462d6_UAIdSXuZM71S4bTz44 from "/builds/stimuleringsfonds-creatieve-industrie/stimuleringsfonds-frontend/node_modules/.pnpm/@pinia+nuxt@0.10.1_magicast@0.3.5_pinia@3.0.1_typescript@5.8.2_vue@3.5.13_typescript@5.8.2__/node_modules/@pinia/nuxt/dist/runtime/payload-plugin.js";
import revive_payload_client_TWukbQleWmFVYO53yp9ZIn19f5lfPCeXVi5CYt3eU6k from "/builds/stimuleringsfonds-creatieve-industrie/stimuleringsfonds-frontend/node_modules/.pnpm/nuxt@3.16.1_@parcel+watcher@2.5.1_@types+node@22.13.13_db0@0.3.1_eslint@8.57.1_ioredis@_e9ae249bc6db273cd69bd4f8870fc4ce/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_MNmHZECQBE_i4kH8T9OlTiHEppXPIQ1JMVs_g4sRXfQ from "/builds/stimuleringsfonds-creatieve-industrie/stimuleringsfonds-frontend/node_modules/.pnpm/nuxt@3.16.1_@parcel+watcher@2.5.1_@types+node@22.13.13_db0@0.3.1_eslint@8.57.1_ioredis@_e9ae249bc6db273cd69bd4f8870fc4ce/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_Y_tKbyCkMJVdxx7AKyo0B8YfbGp5olnIDfB_dC_eQLE from "/builds/stimuleringsfonds-creatieve-industrie/stimuleringsfonds-frontend/node_modules/.pnpm/nuxt@3.16.1_@parcel+watcher@2.5.1_@types+node@22.13.13_db0@0.3.1_eslint@8.57.1_ioredis@_e9ae249bc6db273cd69bd4f8870fc4ce/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import _0_siteConfig_0KkU7ALq0s1Wln6RC4sEfQzUt6VqTtLPLbYGlQY8Z5A from "/builds/stimuleringsfonds-creatieve-industrie/stimuleringsfonds-frontend/node_modules/.pnpm/nuxt-site-config@3.1.7_magicast@0.3.5_vue@3.5.13_typescript@5.8.2_/node_modules/nuxt-site-config/dist/runtime/app/plugins/0.siteConfig.js";
import payload_client__VI55g0_YOYUaOglTOwk0OE8rUHxZ4D3RLWlEWQ50ks from "/builds/stimuleringsfonds-creatieve-industrie/stimuleringsfonds-frontend/node_modules/.pnpm/nuxt@3.16.1_@parcel+watcher@2.5.1_@types+node@22.13.13_db0@0.3.1_eslint@8.57.1_ioredis@_e9ae249bc6db273cd69bd4f8870fc4ce/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_atBWtR2aQf42NCPfPc0TNy8PBAWToJ1WHHLBJXVnTvc from "/builds/stimuleringsfonds-creatieve-industrie/stimuleringsfonds-frontend/node_modules/.pnpm/nuxt@3.16.1_@parcel+watcher@2.5.1_@types+node@22.13.13_db0@0.3.1_eslint@8.57.1_ioredis@_e9ae249bc6db273cd69bd4f8870fc4ce/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client_DhLbw3YOlhRYyplOK9ReyuyRI513TId1ioYkFgFzTUU from "/builds/stimuleringsfonds-creatieve-industrie/stimuleringsfonds-frontend/node_modules/.pnpm/nuxt@3.16.1_@parcel+watcher@2.5.1_@types+node@22.13.13_db0@0.3.1_eslint@8.57.1_ioredis@_e9ae249bc6db273cd69bd4f8870fc4ce/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import chunk_reload_client_rNvnwqnrmDe55TfryufwUdM_t2w1DtH_lJi9dXiAPB4 from "/builds/stimuleringsfonds-creatieve-industrie/stimuleringsfonds-frontend/node_modules/.pnpm/nuxt@3.16.1_@parcel+watcher@2.5.1_@types+node@22.13.13_db0@0.3.1_eslint@8.57.1_ioredis@_e9ae249bc6db273cd69bd4f8870fc4ce/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import cross_origin_prefetch_client_sUPIkCZM3xyc3m3mPxPif1VBytSfEqYHufhfPj9L9kw from "/builds/stimuleringsfonds-creatieve-industrie/stimuleringsfonds-frontend/node_modules/.pnpm/nuxt@3.16.1_@parcel+watcher@2.5.1_@types+node@22.13.13_db0@0.3.1_eslint@8.57.1_ioredis@_e9ae249bc6db273cd69bd4f8870fc4ce/node_modules/nuxt/dist/app/plugins/cross-origin-prefetch.client.js";
import plugin_Yr8QKXSvOBLCCz8kYqkalm6sJAUZyW1_LizV94ERmms from "/builds/stimuleringsfonds-creatieve-industrie/stimuleringsfonds-frontend/.nuxt/modules/@nuxt-scripts/plugin.mjs";
import plugin_vue3_VOBLJeFFdlw9Z7wNERmNnNdg4S5xCSiHp3LAaOiknDo from "/builds/stimuleringsfonds-creatieve-industrie/stimuleringsfonds-frontend/node_modules/.pnpm/@pinia+nuxt@0.10.1_magicast@0.3.5_pinia@3.0.1_typescript@5.8.2_vue@3.5.13_typescript@5.8.2__/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.js";
import components_plugin_z4hgvsiddfKkfXTP6M8M4zG5Cb7sGnDhcryKVM45Di4 from "/builds/stimuleringsfonds-creatieve-industrie/stimuleringsfonds-frontend/.nuxt/components.plugin.mjs";
import prefetch_client_QiA6DlX7dhLEAIt5dWl37z4G7_f8r2jyd33F05t4wM4 from "/builds/stimuleringsfonds-creatieve-industrie/stimuleringsfonds-frontend/node_modules/.pnpm/nuxt@3.16.1_@parcel+watcher@2.5.1_@types+node@22.13.13_db0@0.3.1_eslint@8.57.1_ioredis@_e9ae249bc6db273cd69bd4f8870fc4ce/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import i18n_hC6A3DIanD8Hn4pRVH0UFAV16cB24l_P4EUpCBMwmZY from "/builds/stimuleringsfonds-creatieve-industrie/stimuleringsfonds-frontend/node_modules/.pnpm/nuxt-site-config@3.1.7_magicast@0.3.5_vue@3.5.13_typescript@5.8.2_/node_modules/nuxt-site-config/dist/runtime/app/plugins/i18n.js";
import plugin_aSOYDLU_s7gdDJlSdx8br7spHtOCXL3urBZc9ZCP1k0 from "/builds/stimuleringsfonds-creatieve-industrie/stimuleringsfonds-frontend/node_modules/.pnpm/@nuxtjs+algolia@1.11.2_@vue+server-renderer@3.5.13_vue@3.5.13_typescript@5.8.2___magica_57885559ad0e4c2ac9be8101f6d418be/node_modules/@nuxtjs/algolia/dist/runtime/plugin.mjs";
import switch_locale_path_ssr_sStg65EQ5nkxfXtBT0734h06FpRdjSXNq7SoN7FAyA8 from "/builds/stimuleringsfonds-creatieve-industrie/stimuleringsfonds-frontend/node_modules/.pnpm/@nuxtjs+i18n@9.4.0_eslint@8.57.1_magicast@0.3.5_rollup@4.37.0_vue@3.5.13_typescript@5.8.2_/node_modules/@nuxtjs/i18n/dist/runtime/plugins/switch-locale-path-ssr.js";
import route_locale_detect_LaYCA_QYkP8_D8y7UFJdGi_OYFyYxWjhDzVAnsYDZxM from "/builds/stimuleringsfonds-creatieve-industrie/stimuleringsfonds-frontend/node_modules/.pnpm/@nuxtjs+i18n@9.4.0_eslint@8.57.1_magicast@0.3.5_rollup@4.37.0_vue@3.5.13_typescript@5.8.2_/node_modules/@nuxtjs/i18n/dist/runtime/plugins/route-locale-detect.js";
import i18n_ySssBl1QbnfFGhJ0jq0tDsYWeuudKzq5xWxIUG9kJPI from "/builds/stimuleringsfonds-creatieve-industrie/stimuleringsfonds-frontend/node_modules/.pnpm/@nuxtjs+i18n@9.4.0_eslint@8.57.1_magicast@0.3.5_rollup@4.37.0_vue@3.5.13_typescript@5.8.2_/node_modules/@nuxtjs/i18n/dist/runtime/plugins/i18n.js";
import plugin_1zJieFBIUMXwakz5TDyppu_dAEDe4qC6loY_xPh3v08 from "/builds/stimuleringsfonds-creatieve-industrie/stimuleringsfonds-frontend/node_modules/.pnpm/@digitalnatives+graphql-client@4.2.0_graphql@16.10.0_magicast@0.3.5_nuxt@3.16.1_@parcel_ee49cd64baabea05e23d3cd482bb9bd7/node_modules/@digitalnatives/graphql-client/dist/runtime/plugin.js";
import plugin_CTz1dCW0mIhOMd9CIA0WC1_x9wDBcROb22_wDKfPIYo from "/builds/stimuleringsfonds-creatieve-industrie/stimuleringsfonds-frontend/node_modules/.pnpm/nuxt-bugsnag@8.4.0_@bugsnag+core@8.2.0_magicast@0.3.5_vue-router@4.5.0_vue@3.5.13_typescript@5.8.2__/node_modules/nuxt-bugsnag/dist/runtime/client/plugin.js";
import content_placeholders_X_zNU74Je2hoUIfWtDdsGL_XbQgVXZGGt7_36tl5KuI from "/builds/stimuleringsfonds-creatieve-industrie/stimuleringsfonds-frontend/plugins/content-placeholders.js";
import router_S6yKjqoQ3Sb8o2lehB5PRjhy2uyi4DI_Ja_jhT1JS60 from "/builds/stimuleringsfonds-creatieve-industrie/stimuleringsfonds-frontend/plugins/router.ts";
import v_clipboard_client_tUTVpZzKLsbj9PnqffMlAc9Tn9gbhioIF71I1H_HbTY from "/builds/stimuleringsfonds-creatieve-industrie/stimuleringsfonds-frontend/plugins/v-clipboard.client.js";
import ssg_detect_jVslNS8kNsZpMsdZRAZwHEWE1UfmZWyrcBfT8Y1YA7c from "/builds/stimuleringsfonds-creatieve-industrie/stimuleringsfonds-frontend/node_modules/.pnpm/@nuxtjs+i18n@9.4.0_eslint@8.57.1_magicast@0.3.5_rollup@4.37.0_vue@3.5.13_typescript@5.8.2_/node_modules/@nuxtjs/i18n/dist/runtime/plugins/ssg-detect.js";
export default [
  payload_plugin_w0HDFr_j2IsnaU6rXjh462d6_UAIdSXuZM71S4bTz44,
  revive_payload_client_TWukbQleWmFVYO53yp9ZIn19f5lfPCeXVi5CYt3eU6k,
  unhead_MNmHZECQBE_i4kH8T9OlTiHEppXPIQ1JMVs_g4sRXfQ,
  router_Y_tKbyCkMJVdxx7AKyo0B8YfbGp5olnIDfB_dC_eQLE,
  _0_siteConfig_0KkU7ALq0s1Wln6RC4sEfQzUt6VqTtLPLbYGlQY8Z5A,
  payload_client__VI55g0_YOYUaOglTOwk0OE8rUHxZ4D3RLWlEWQ50ks,
  navigation_repaint_client_atBWtR2aQf42NCPfPc0TNy8PBAWToJ1WHHLBJXVnTvc,
  check_outdated_build_client_DhLbw3YOlhRYyplOK9ReyuyRI513TId1ioYkFgFzTUU,
  chunk_reload_client_rNvnwqnrmDe55TfryufwUdM_t2w1DtH_lJi9dXiAPB4,
  cross_origin_prefetch_client_sUPIkCZM3xyc3m3mPxPif1VBytSfEqYHufhfPj9L9kw,
  plugin_Yr8QKXSvOBLCCz8kYqkalm6sJAUZyW1_LizV94ERmms,
  plugin_vue3_VOBLJeFFdlw9Z7wNERmNnNdg4S5xCSiHp3LAaOiknDo,
  components_plugin_z4hgvsiddfKkfXTP6M8M4zG5Cb7sGnDhcryKVM45Di4,
  prefetch_client_QiA6DlX7dhLEAIt5dWl37z4G7_f8r2jyd33F05t4wM4,
  i18n_hC6A3DIanD8Hn4pRVH0UFAV16cB24l_P4EUpCBMwmZY,
  plugin_aSOYDLU_s7gdDJlSdx8br7spHtOCXL3urBZc9ZCP1k0,
  switch_locale_path_ssr_sStg65EQ5nkxfXtBT0734h06FpRdjSXNq7SoN7FAyA8,
  route_locale_detect_LaYCA_QYkP8_D8y7UFJdGi_OYFyYxWjhDzVAnsYDZxM,
  i18n_ySssBl1QbnfFGhJ0jq0tDsYWeuudKzq5xWxIUG9kJPI,
  plugin_1zJieFBIUMXwakz5TDyppu_dAEDe4qC6loY_xPh3v08,
  plugin_CTz1dCW0mIhOMd9CIA0WC1_x9wDBcROb22_wDKfPIYo,
  content_placeholders_X_zNU74Je2hoUIfWtDdsGL_XbQgVXZGGt7_36tl5KuI,
  router_S6yKjqoQ3Sb8o2lehB5PRjhy2uyi4DI_Ja_jhT1JS60,
  v_clipboard_client_tUTVpZzKLsbj9PnqffMlAc9Tn9gbhioIF71I1H_HbTY,
  ssg_detect_jVslNS8kNsZpMsdZRAZwHEWE1UfmZWyrcBfT8Y1YA7c
]