import { hash } from 'ohash';
import { flatMapDeep, sample } from 'lodash-es';

const serialize = (thing) => {
    return hash(
        JSON.stringify(
            flatMapDeep(
                thing,
                (value, index) => [index, toValue(value)]
            )
        )
    );
};


/**
 * Pick one item from an array randomly and make sure the state is set correctly
 * so that there will be no hydration errors.
 *
 * @template T
 * @param {MaybeRefOrGetter<T[]>} mayberef
 * @return ComputedRef<T>
 */
export const useRandomHydrated = (mayberef) => {
    const ref = toRef(mayberef);
    const key = computed(() => serialize(ref.value));

    return computed(() => useState(key.value, () => sample(ref.value ?? [])).value);
};

